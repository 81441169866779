import React, { useEffect, useState } from 'react'
import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { FiExternalLink, FiX } from 'react-icons/fi'
import Emoji from '../components/Emoji'

const BottomBanner = () => {
  const [showBanner, setShowBanner] = useState(false)

  const dontShowBanner = () => {
    localStorage.setItem('dontShowShops', 'true')
    setShowBanner(false)
  }

  const goToBanner = () => {
    window.open('https://shop.wa.link/pt', '_blank')
  }

  useEffect(() => {
    const dontShowBanner = localStorage.getItem('dontShowShops')
    if (dontShowBanner) {
      setShowBanner(false)
    } else {
      setShowBanner(true)
    }
  }, [])

  if (!showBanner) return null

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#1fd1f9',
        backgroundImage: 'linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)',
      }}
    >
      <p
        style={{
          color: '#fff',
          fontSize: '14px',
          margin: '10px',
          textAlign: 'center',
        }}
      >
        {' '}
        <Emoji symbol={'️🔥'} /> Temos um novo serviço:{' '}
        <strong>Lojas online para WhatsApp!</strong> Saiba mais sobre suas
        funcionalidades aqui:
      </p>

      <Button
        shape={SHAPE.pill}
        size={SIZE.compact}
        onClick={goToBanner}
        overrides={{
          BaseButton: {
            style: {
              marginBottom: '0.5rem',
              marginRight: '1rem',
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
            },
          },
        }}
      >
        Ir para as Lojas Walink
        <span style={{ marginLeft: '5px' }}>
          <FiExternalLink size="15" />
        </span>
      </Button>
      <Button
        shape={SHAPE.pill}
        size={SIZE.compact}
        kind={KIND.secondary}
        onClick={dontShowBanner}
        overrides={{
          BaseButton: {
            style: {
              marginBottom: '0.5rem',
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
            },
          },
        }}
      >
        Fechar
        <span style={{ marginLeft: '5px' }}>
          <FiX size="13" />
        </span>
      </Button>
    </div>
  )
}

export default BottomBanner
